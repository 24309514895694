@import '../../var.scss';

.hero {
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 80vh;
    width: 100vw;
    position: relative;
    z-index: -1;
    background-color: $nine;
  
    .heading-text {
      position: absolute;
      font-weight: 600;
      font-size: 40px;
      bottom: 50px;
      color: #fff;
      text-align: center;
      max-width: 1000px;
      left: 0;
      right: 0;
      margin: auto;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      z-index: 2;
      // text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    }

    .opacity {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .hero-logo {
      max-width: 100%;
      max-height: 70%;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
      padding: 0 10px;
      position: absolute;
    }
}

@media only screen and (max-width: 1000px) {
  .hero {
    height: 35vh;

    .hero-logo {
      font-size: 35px;
    }

    .heading-text {
      font-size: 18px;
      padding: 0 5px;
    }
  }
}